import { type Price } from '@commercetools/platform-sdk/dist/commercetools-platform-sdk.cjs'
import { useTranslation } from 'react-i18next'
import Body from '#app/components/typography/body'
import InformationTooltip from '#app/features/shopping-bag/components/information-tooltip'
import { cn } from '#app/utils/misc'
import { moneyFormatWithTypedMoney } from '#app/utils/money'
import { type StyleVariant, type PlentyVariant } from '#types/product'

type PriceTagProps = {
	activeStyle: StyleVariant
	variant: PlentyVariant | undefined
	isSelectedSku: boolean
	storeParams?: string[]
}

export const PriceTag = ({
	activeStyle,
	variant,
	isSelectedSku,
	storeParams,
}: PriceTagProps) => {
	let { t } = useTranslation('product_details_page')
	if (!variant) return null
	let price = isSelectedSku ? variant.lowestPrice : activeStyle.lowestPrice
	let channelFromStoresParam: string | undefined = undefined

	if (storeParams?.length) {
		channelFromStoresParam = variant.availability?.find(
			it => it.storeKey && storeParams.includes(it.storeKey),
		)?.channelKey
		let storePrice
		if (price.channel?.id === channelFromStoresParam) storePrice = price
		else {
			storePrice = variant.prices?.find(
				price => price.channel?.id === channelFromStoresParam,
			)
		}

		price = storePrice || price // Fallback to the generic price if no specific store price is found
	}

	const isDiscounted =
		price && price.discounted && price.discounted.discount.obj?.isActive
	let priceToShow = isDiscounted ? price.discounted!.value : price.value
	const originalPrice = moneyFormatWithTypedMoney(price.value)

	const isSamePrice = isSelectedSku
		? true
		: arePricesEqual(activeStyle.variants, channelFromStoresParam)
	if (!isSamePrice) {
		return (
			<div className="flex items-center md:space-x-4">
				<div className="flex flex-row">
					<Body
						as="m"
						className={cn(
							'text-[14px] tracking-[-0.21px] md:text-[16px] md:tracking-[-0.24px]',
						)}
					>
						<span>{t('from_label') + ' '}</span>
						<span className="font-bold text-black-90">
							{`${moneyFormatWithTypedMoney(priceToShow)}`}
						</span>
					</Body>

					<span className="ml-2">
						<InformationTooltip />
					</span>
				</div>
			</div>
		)
	}

	return (
		<div className="flex items-center md:space-x-2">
			<span>
				<Body
					as="m"
					weight={`${isDiscounted ? 'bold' : 'regular'}`}
					className={cn(
						'text-[14px] tracking-[-0.21px] md:text-[16px] md:tracking-[-0.24px]',
					)}
				>
					<span className={cn(isDiscounted ? 'text-red' : 'text-beige-100')}>
						{`${moneyFormatWithTypedMoney(priceToShow)}`}
					</span>
				</Body>
			</span>
			{isDiscounted && (
				<div className="flex flex-row items-center space-x-2 md:space-x-2">
					<Body as="xs" className="text-green-40 line-through">
						{t('original_price', {
							price: originalPrice,
						})}
					</Body>
					<Body as="xs" className="text-red">
						{getProductPriceDiscountPercentage(price, isDiscounted)}%
					</Body>
				</div>
			)}
		</div>
	)
}

export function arePricesEqual(
	variants: PlentyVariant[],
	channelFromStoresParam: string | undefined,
) {
	const storePrices = variants.flatMap(variant =>
		channelFromStoresParam
			? variant.prices?.filter(
					price => price.channel?.id === channelFromStoresParam,
			  )
			: variant.prices,
	)

	if (storePrices.length === 0) {
		return false
	}

	const firstPriceAmount = storePrices[0]?.discounted
		? storePrices[0].discounted.value.centAmount
		: storePrices[0]?.value.centAmount

	return storePrices.every(price => {
		const priceAmount = price?.discounted
			? price.discounted.value.centAmount
			: price?.value.centAmount
		return priceAmount === firstPriceAmount
	})
}

const getProductPriceDiscountPercentage = (
	price: Price,
	isDiscounted: boolean,
) => {
	let discountPercentage = 0

	const discountType = price.discounted?.discount.obj?.value.type

	if (
		discountType === 'relative' &&
		price.discounted?.discount.obj?.value.permyriad
	) {
		discountPercentage = price.discounted?.discount.obj?.value.permyriad / 100
	} else if (
		isDiscounted &&
		price.discounted?.discount.obj?.value.type === 'absolute'
	) {
		try {
			discountPercentage = parseFloat(
				(
					(price.discounted?.discount.obj?.value.money[0].centAmount /
						price.value.centAmount) *
					100
				).toFixed(2),
			)
		} catch (error) {
			console.error('Error calculating discount percentage', error)
		}
	}

	return discountPercentage
}
