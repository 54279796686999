import { Link } from '@remix-run/react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import ProductCard from '#app/components/common/product-card/product-card'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import PlentyHeading from '#app/components/typography/plenty-heading'
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from '#app/components/ui/carousel'
import { Icon } from '#app/components/ui/icon'
import ClerkProductCard from '#app/features/plp-clerk/clerk-components/clerk-product-card/clerk-product-card'
import { useClerkVisitorStore } from '#app/store/clerk-visitor'
import { logClickEvent } from '#app/utils/tracking/clerk-tracking' // Import logClickEvent
import { type PlentyProduct, type ClerkProduct } from '#types/product'

type ProductCarouselProps = {
	clerkProducts?: ClerkProduct[]
	title: string
	subtitle: string // deprecated
	linkText?: string //
	linkUrl?: string //
	storeParams?: string[]
	commercetoolsProducts?: PlentyProduct[]
	smallVersion?: boolean
}

export function ProductCarousel({
	clerkProducts,
	title,
	subtitle,
	linkText,
	linkUrl,
	storeParams,
	commercetoolsProducts,
	smallVersion,
}: ProductCarouselProps) {
	const { clerkVisitor } = useClerkVisitorStore()

	if (!clerkProducts) {
		return null
	}
	const isClient = typeof window !== 'undefined'

	const [products, CardComponent] = clerkProducts?.length
		? [clerkProducts, ClerkProductCard]
		: [commercetoolsProducts, ProductCard]

	const productType = clerkProducts?.length ? 'clerk' : 'commercetools'

	const handleClick = (product: any, index: number) => {
		if (productType === 'clerk') {
			logClickEvent(product.id, index, false, clerkVisitor!)
		}
	}

	return (
		<>
			<div className="flex flex-col space-y-6 overflow-x-auto scrollbar-hide">
				<div className="flex w-full flex-col items-baseline justify-between md:flex-row md:pr-[var(--plenty-padding)]">
					<PlentyHeading as="h7">{title}</PlentyHeading>
					{linkUrl && linkText && (
						<Link
							className="group relative flex items-center"
							to={linkUrl || '/'}
						>
							<PlentyButtonsAndLinks as="btn-sm" className="relative">
								{linkText}
							</PlentyButtonsAndLinks>
							<span className="absolute bottom-0 left-0 h-[4px] w-full bg-beige-50 transition-colors duration-300 group-hover:bg-beige-90" />
							<Icon
								className="ml-2 h-4 w-4 transform transition-transform duration-300 group-hover:translate-x-1"
								name="right-arrow"
							/>
						</Link>
					)}
				</div>
				<Carousel
					plugins={[WheelGesturesPlugin()]}
					opts={{ dragFree: true }}
					className="w-full"
				>
					<CarouselContent>
						{products?.length ? (
							products.map((product, index) => (
								<CarouselItem
									key={index}
									className={`${
										smallVersion
											? 'min-w-[168px] pl-2 sm:min-w-[180px] md:pl-4'
											: 'min-w-[220px]'
									} basis-[32%] md:min-w-[285px] md:basis-[30%] lg:basis-[25%] xl:basis-[18%] 2xl:basis-[13%]`}
								>
									<Link
										to={generateProductLink(product, productType, storeParams)}
										state={{
											amplitudePageSource: isClient ? window.location.href : '',
										}}
										onClick={() => handleClick(product, index)}
									>
										<CardComponent
											isSlider={true}
											product={product}
											lazyLoad
											priority="low"
										/>
									</Link>
								</CarouselItem>
							))
						) : (
							<div>No products available</div>
						)}
					</CarouselContent>
					{/*<CarouselPrevious size={'default'} />*/}
					{/*<CarouselNext size={'default'} />*/}
				</Carousel>
			</div>
		</>
	)
}

function generateProductLink(product: any, productType: any, storeParams: any) {
	const storeParam = storeParams?.length ? `&store=${storeParams[0]}` : ''
	if (productType === 'clerk') {
		return `/product/${product.style_id}${storeParam}`
	} else {
		return `/product/${product.styleNumber}?style=${product.previewVariant.styleVariantKey}${storeParam}`
	}
}
